* {
  box-sizing: border-box;
}

body {
  background-color: darkgray;
}

.App {
  flex: 1;
}

.App-header {
  background-color: #566583;
  min-height: 100px;
  position: relative;
  color: white;
  width: 100%;
}
.App-header .welcome {
  font-size: 15px;
  position: absolute;
  bottom: 40px;
  right: 50px;
}
.App-header .logout {
  font-size: 15px;
  position: absolute;
  bottom: 10px;
  right: 50px;
}
.App-header .logo {
  height: 150px;
  padding: 10px;
  margin-left: 30px;
}

.login {
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  width: 400px;
  background-color: white;
  border-radius: 5px;
}
.login .inputDiv {
  display: block;
  padding: 5px;
  margin: 10px;
}
.login .inputDiv label {
  color: black;
  width: 100px;
  display: inline-block;
}
.login .hyperlink {
  border: 0px;
  text-decoration: underline;
  background-color: transparent;
  cursor: pointer;
}

.main {
  flex: 1;
}
.main .right {
  flex: 1;
  background-color: #bbb;
}

.menu .menuItem {
  width: 100%;
  clear: right;
  margin: 0px;
  transition-duration: 0.4s;
  background-color: darkgray;
  height: 50px;
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  padding-left: 50px;
  color: white;
  border: 0px;
}
.menu .menuItem:hover {
  background-color: lightgray;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.books .book {
  justify-content: left;
  align-content: space-between;
  width: 100%;
  padding: 10px;
}
.books .book .image {
  padding-right: 20px;
}
.books .book .image img {
  max-width: 100px;
  height: auto;
  box-shadow: 10px #566583;
}
.books .book .details {
  vertical-align: top;
  flex: 0.7;
  padding: 0px;
}
.books .book .details p {
  margin: 0px;
}
.books .book .details h1 {
  margin: 0px;
  color: white;
  text-shadow: 1px 1px 2px black, 0 0 25px darkgray, 0 0 5px darkblue;
  flex: 1;
}
.books .book .details .rating {
  vertical-align: top;
  padding: 0px;
  margin: 0px;
  clear: both;
}
.books .book .details .rating label {
  width: 100px;
  float: left;
}
.books .book:hover:not(.detail) {
  background-color: #888888;
}

.breadcrumbs {
  width: 500px;
  padding: 10px;
  /*
  button:not(:first-child):before {
      content: '=>';
      margin-right:10px;
  }

  button:not(:last-child):hover {
      background-color: darkgray;
  }
  */
}
.breadcrumbs button {
  border: 0px;
  background-color: transparent;
  font-size: 12px;
  vertical-align: middle;
  color: white;
  font-weight: bold;
  width: 180px;
}
.breadcrumbs button:not(:first-child) {
  margin-left: -8px;
  background-image: url("../../public/green_crumb.png");
  height: 60px;
  background-size: 100% 100%;
}
.breadcrumbs button:last-child {
  background-image: url("../../public/blue_crumb.png");
  height: 60px;
  background-size: 100% 100%;
}
.breadcrumbs button:first-child {
  background-image: url("../../public/home_crumb.png");
  background-size: 100% 100%;
  height: 60px;
  width: 80px;
}

.home {
  padding: 10px;
}
.home .meetingDetails {
  width: 100%;
}
.home .cover {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
.home .cover img {
  max-width: 100%;
  max-height: 100%;
}
.home .blurb {
  padding-left: 10px;
  padding-right: 10px;
  flex: 1;
  vertical-align: top;
  min-width: 300px;
  text-align: justify;
}

.review {
  border: 1px solid darkgray;
  border-radius: 5px;
  padding: 20px;
  flex: 1;
  box-shadow: 5px 10px #888888;
  margin-bottom: 50px;
  margin-top: 50px;
}
.review textarea {
  width: 100%;
  height: 300px;
}

.questions .question {
  width: 100%;
}
.questions .question h2 {
  margin: 0px;
  color: white;
  text-shadow: 1px 1px 2px black, 0 0 25px darkgray, 0 0 5px darkblue;
}
.questions .question textarea {
  height: 300px;
  width: 100%;
}

.recommendation {
  padding: 10px;
}

.formField {
  clear: both;
  margin-bottom: 10px;
  width: 500px;
}
.formField img {
  height: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.addBook {
  padding: 10px;
}
.addBook textarea {
  width: 500px;
  height: 300px;
}/*# sourceMappingURL=style.css.map */