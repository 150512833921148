//allows us to include the padding and border in an element's total width and height
    * {
        box-sizing: border-box;
      }
      
    
    body {

        background-color: darkgray;
      }
    
    .App {
        flex:1;
    }

    .App-header {
        background-color: #566583;
        min-height: 100px;
        //display: flex;
        //flex-direction: column;
        //align-items: center;
        //justify-content: center;
        //font-size: calc(10px + 2vmin);
        position: relative;
        color: white;
        width:100%;
        //flex:1;

        .welcome {
            font-size:15px;
            //justify-content: left;
            //border:1px solid red;
            position: absolute;
            bottom: 40px;
            right:50px;
         
        }

        .logout {
            font-size:15px;
            //justify-content: left;
            //border:1px solid red;
            position: absolute;
            bottom: 10px;
            right:50px;
        }

        .logo {
            height:150px;
            padding:10px;
            margin-left:30px;
        }
    }

//login

.login {

    margin-top:50px;

    margin-left: auto;
    margin-right: auto;

    width:400px;
    background-color: white;
    border-radius: 5px;


    .inputDiv {
        display: block;
        //text-align: left;
        padding:5px;
        margin: 10px;

        label {
            color: black;
            width:100px;
            display:inline-block;
            //border:1px solid red;
        }
        
    }

    .hyperlink {
        border:0px;
        text-decoration: underline;
        background-color: transparent;
        cursor: pointer;
    }

}



// main


.main {

    //width:100%;
    flex: 1;

    .column {
        //float: left;    
        //display: inline-block;
      }

    .left {
        //width: 25%;
        //background-color:red;
    }

    .right {
        //width: 80%;
        flex: 1;
        //min-width: 500px;
        background-color:#bbb;
    }


}



// menu

.menu {



    .menuItem {
        width:100%;
        //flex:1;
        clear:right;
        margin:0px;
        transition-duration: 0.4s;
        background-color: darkgray;
        height:50px;
        font-weight:bold;
        font-size: 20px;
        text-align: left;
        padding-left: 50px;
        color:white;
        border:0px;
    }

    .menuItem:hover {
        background-color: lightgray;
    }

}

.flex {
    display: flex;
    flex-wrap: wrap;
  }

.books {

    .book {

        justify-content: left;
        align-content: space-between;
        //clear:right;

        width:100%;
        //display: inline-block;
        //clear:both;
        padding:10px;

        .image {
            //float:left;
            //width:15%;
            //min-block-size: 200px;
            padding-right:20px;

            img {
                max-width:100px;
                height:auto;
                box-shadow: 10px #566583;
            }
        }

        .details {
            vertical-align: top;
            //float:left;
            flex: 0.7;
            padding:0px;
            
            p {
                margin:0px;
            }

            h1 {
                margin:0px;
                color: white;
                text-shadow: 1px 1px 2px black, 0 0 25px darkgray, 0 0 5px darkblue;
                flex: 1;
              }

              .rating
              {
                vertical-align: top;
                //border:1px solid red;
                //height:80px;
                padding:0px;
                margin:0px;
                clear:both;
             
                    label {
                        width:100px;
                        //height:80px;
                        //border:1px solid pink;
                        //display:inline-block;
                        float: left;
                        
                    }
                
 
              }
        }

    }

    .book:hover:not(.detail) {
        background-color: #888888;
    }

}

.breadcrumbs
{
    width:500px;
    padding:10px;
    //overflow: hidden;
    button {
        border:0px;
        
        
        background-color:transparent;
        //opacity: 0.5;
        font-size:12px;
        vertical-align: middle;
        color:white;
        font-weight: bold;
        //height:60px;
        width:180px;
    }

    button:not(:first-child) {
        margin-left:-8px;
        background-image: url('../../public/green_crumb.png');
        //width:15%;
        height:60px;
        background-size: 100% 100%;
    }

    button:last-child {
        background-image: url('../../public/blue_crumb.png');
       // width:15%;
        height:60px;
        background-size: 100% 100%;
    }

    button:first-child {
        background-image: url('../../public/home_crumb.png');
        //width:6%;
        background-size: 100% 100%;
        height:60px;
        width:80px;
        
    }

    /*
    button:not(:first-child):before {
        content: '=>';
        margin-right:10px;
    }

    button:not(:last-child):hover {
        background-color: darkgray;
    }
    */

   

}

.home {

    //flex:1;
    padding:10px;




    .meetingDetails {
        width:100%;
        //border:1px solid green;
    }

    .cover {
        width:300px;
        //flex:0.2;
       // flex-grow:1;
        //border:1px solid green;
        margin-left:auto;
        margin-right:auto;

        img {
            max-width:100%;
            max-height:100%;
        }
    }

    .blurb {
        //width:80%;
        padding-left:10px;
        padding-right:10px;
        flex:1;
        vertical-align: top;
        min-width: 300px;
        text-align: justify;
    }
}

.review {
    border:1px solid darkgray;
    border-radius: 5px;
    padding:20px;
    flex: 1;
    box-shadow: 5px 10px #888888;
    margin-bottom: 50px;
    margin-top: 50px;

    textarea {
        width:100%;
        height:300px;
    }
}

.questions
{
   

    .question {

        width:100%;
 
        h2 {
            margin:0px;
            color: white;
            text-shadow: 1px 1px 2px black, 0 0 25px darkgray, 0 0 5px darkblue;
            
        }

        textarea {
            
            height:300px;
            width:100%;
        }
    }
}

.recommendation {
    padding:10px;
}

.formField {
    clear:both;
    margin-bottom: 10px;;
    width:500px;

    img {
        height:200px;
        display:block;
        margin-left:auto;
        margin-right:auto;
    }
}


.addBook {
    padding:10px;
    textarea {
        width:500px;
        height:300px;

    }

    button {
       
    }


}